<template>
  <div class="goods-page-container">
    <market-header @change="headerParamChange" tab-base-url="/shop-goods/goods" />

    <en-table-layout ref="tableLayout" :tableData="pageData.data" :loading="loading" :toolbar-two="true"
      :toolbar-three="params.self_goods === 2 ? true : false" :redundancy-height="49"
      @selection-change="handleSelectionChange" @sort-change="handleSortChange">
      <!-- <template slot="toolbar">
        <el-form-item label="会员价" class="col-auto">
          <el-input
            style="width: 100px;"
            size="medium"
            :controls="false"
            v-model="params.low_price"
          />
          <span style="margin: 0 4px;">-</span>
          <el-input
            style="width: 100px;"
            size="medium"
            :controls="false"
            v-model="params.high_price"
          />
        </el-form-item>

        <el-form-item label="利润率(%)" class="col-auto" v-if="is_qiye_flag">
          <el-input
            style="width: 100px;"
            size="medium"
            :controls="false"
            v-model="params.profit_min"
          />
          <span style="margin: 0 4px;">-</span>
          <el-input
            style="width: 100px;"
            size="medium"
            :controls="false"
            v-model="params.profit_max"
          />
        </el-form-item>

        <el-form-item label="市场价" class="col-auto">
          <el-input
            style="width: 100px;"
            size="medium"
            :controls="false"
            v-model="params.low_mktprice"
          />
          <span style="margin: 0 4px;">-</span>
          <el-input
            style="width: 100px;"
            size="medium"
            :controls="false"
            v-model="params.high_mktprice"
          />
        </el-form-item>

        <div class="col-auto">
          <el-button @click="searchEvent" size="small" type="primary">
            搜索
          </el-button>
        </div>

        <div class="col"></div>

        <div class="col-auto mr-2">
          <el-checkbox
            v-model="checked"
            @change="che"
          >
            只看未挑选
          </el-checkbox>
        </div>

        <div class="col-auto">
          <el-button
            @click="selectGoodsAll"
            v-if="is_qiye_flag"
            size="small"
            type="primary"
          >
            批量挑选
          </el-button>
        </div>
      </template>-->

      <!-- <template slot="toolbar-two">
        <el-form-item v-if="params.self_goods===2||params.self_goods===0" label="商品配送类型" label-width="100px" style="margin-right: 15px">
          <el-select
          style="width: 85%;"
          v-model="params.ship_method"
          placeholder="请选择商品状态"
          clearable
        >
          <el-option label="全部" value=""/>
          <el-option label="由厂家配送" :value="2"/>
          <el-option label="京东自营配送" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="params.self_goods===2"
          label="地区"
          :style="{width: areaNum * 110+ 60 +'px', marginLeft: '14px'}"
        >
          <el-select
            size="small"
            class="choose-machine center"
            v-model="areaSelectData.province"
            placeholder="省/直辖市"
            style="width:105px;margin-right: 5px"
            clearable
          >
            <el-option
              v-for="(item,index) in this.provinceList"
              :label="item.name"
              :value="item.id"
              :key="index">
            </el-option>
          </el-select>
          <el-select
            v-if="areaNum > 1"
            size="small"
            class="choose-machine center"
            v-model="areaSelectData.city"
            placeholder="城市/地区"
            style="width:105px;margin-right: 5px"
            clearable
          >
            <el-option
              v-for="(item,index) in this.cityList"
              :label="item.name"
              :value="item.id"
              :key="index">
            </el-option>
          </el-select>
          <el-select
            v-if="areaNum > 2"
            size="small"
            class="choose-machine center"
            v-model="areaSelectData.county"
            placeholder="县/区"
            style="width:105px;margin-right: 5px"
            clearable
          >
            <el-option
              v-for="(item,index) in this.countyList"
              :label="item.name"
              :value="item.id"
              :key="index">
            </el-option>
          </el-select>
          <el-select
            v-if="areaNum > 3"
            size="small"
            class="choose-machine center"
            v-model="areaSelectData.town"
            placeholder="乡镇/街道"
            style="width:105px;margin-right: 5px"
            clearable
          >
            <el-option
              v-for="(item,index) in this.townList"
              :label="item.name"
              :value="item.id"
              :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="params.self_goods===2 && areaSelectData.province!==''"
          label="显示类型"
          :style="{width: MixinIsFormEnterprise ? '200px' : '180px'}"
        >
          <el-select
            size="small"
            class="choose-machine center"
            v-model="params.jd_area_state"
            placeholder="请选择"
            style="width:100px;"
          >
            <el-option
              label="全部"
              :value="0"
            ></el-option>
            <el-option
              label="有货"
              :value="1"
            ></el-option>
            <el-option
              label="无货"
              :value="2"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>-->

      <template slot="toolbar" v-if="params.self_goods === 2">
        <el-row>
          <el-col :span="6">
            <el-form-item label="会员价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_price"></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_price"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="利润率(%)">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_min"></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_max"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              <el-input v-model.trim="searchContent" placeholder="请输入关键字进行搜索" style="width: 320px">
                <el-select style="width: 110px;" slot="prepend" v-model="key_word">
                  <el-option label="商品" value="goods_name"></el-option>
                  <el-option label="商品编号" value="goods_sn"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-button @click="searchEvent('')" size="small" type="primary">搜索</el-button>
            <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
          </el-col>
        </el-row>

        <!-- <div class="col"></div> -->
        <!-- <div >
          <el-button @click="searchEvent('')" size="small" type="primary">筛选</el-button>
          <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
          <el-button @click="addShop" size="small" v-show="params.self_goods===1" type="primary">录入商品</el-button>
        </div>-->
        <!-- 为京东馆 -->
        <!-- <template v-if="canshu.self_goods=='2'">
          <div class="conditions">
            <span>价格：</span>
            <el-input style="width: 60px;" size="medium" :controls="false" v-model="params.min"></el-input>
            <span style="margin: 0 4px;">-</span>
            <el-input style="width: 60px;" size="medium" :controls="false" v-model="params.max"></el-input>
          </div>
        </template>-->
        <!-- <el-button @click="gotoRecycle" size="small" type="primary">回收站</el-button> -->
        <!-- <el-button @click="underBatch" size="small" type="danger">批量下架</el-button> -->
      </template>

      <template slot="toolbar" v-if="params.self_goods !== 2">
        <el-row>
          <el-col :span="7">
            <el-form-item label="会员价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_price"></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_price"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item>
              <el-input v-model.trim="searchContent" placeholder="请输入关键字进行搜索" style="width: 320px">
                <el-select style="width: 120px;" slot="prepend" v-model="key_word">
                  <el-option label="商品" value="goods_name"></el-option>
                  <el-option label="商品编号" value="goods_sn"></el-option>
                  <el-option
                    v-show="(params.self_goods === 3 || params.self_goods === 0) && $store.getters.isSupplierName"
                    label="所属供应商" value="seller_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-checkbox v-model="checked" @change="che">只看未挑选</el-checkbox>
            <el-button style="margin-left: 10px;" @click="searchEvent('')" size="small" type="primary">搜索</el-button>
            <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
            <el-button @click="addShop" size="small" v-show="params.self_goods === 1 && shopInfo.shop_type == 3"
              type="primary">录入商品</el-button>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-two" v-if="params.self_goods === 2">
        <el-row>
          <el-col :span="6">
            <el-form-item label="市场价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_mktprice"></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_mktprice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item v-if="params.self_goods === 2" label="商品配送类型">
              <el-select style="width: 145px" v-model="params.ship_method" placeholder="请选择配送类型" clearable>
                <el-option label="全部" value />
                <el-option label="由厂家配送" :value="2" />
                <el-option label="京东自营配送" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="商品分类">
              <el-cascader clearable style="width:250px" v-model="category" :options="categoryProxy.children"
                :props="categoryProxy.props" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-checkbox v-model="checked" @change="che">只看未挑选</el-checkbox>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-two" v-if="params.self_goods !== 2">
        <el-row>
          <el-col :span="7">
            <el-form-item label="市场价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_mktprice"></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_mktprice"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="9">
            <el-form-item label="商品分类">
              <el-cascader clearable style="width:250px" v-model="category" :options="categoryProxy.children"
                :props="categoryProxy.props" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="利润率(%)">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_min"></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_max"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-three" v-if="params.self_goods === 2">
        <el-row>
          <el-col :span="24">
            <div class="address-border" style="padding:10px;border:1px solid #e9ebf0">
              <el-form-item label="显示类型" v-if="areaSelectData.province !== ''">
                <!-- v-if="areaSelectData.province!==''" -->
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.jd_area_state"
                  placeholder="请选择" style="width:200px;" :disabled="areaSelectData.province === ''">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                  <el-option label="无货" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地区" :style="{ width: areaNum * 120 + 60 + 'px' }">
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.province"
                  placeholder="省/直辖市" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
                <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
                  placeholder="城市/地区" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
                <el-select v-if="areaNum > 2" size="small" class="choose-machine center" v-model="areaSelectData.county"
                  placeholder="县/区" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
                <el-select v-if="areaNum > 3 && this.townList.length > 0" size="small" class="choose-machine center"
                  v-model="areaSelectData.town" placeholder="乡镇/街道" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" />

        <el-table-column label="图片" width="60" fixed="left" class-name="goods-cover-wrapper">
          >
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" class="goods-cover big" alt style="width: 300px" />

              <!-- <router-link :to="'/shop-goods/good/1?&goods_id=' + row.goods_id" slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </router-link> -->
              <div slot="reference" @click="opengoodsclassify(row)">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" min-width="200" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="{row}">
            {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}

          </template>
        </el-table-column>
        <el-table-column label="商品编号" width="120">
          <template slot-scope="scope">
            {{ scope.row.good_sn }}
          </template>
        </el-table-column>
        <el-table-column key="cat_name" width="120" label="商品分类">
          <template slot-scope="scope">{{ scope.row.cat_name }}</template>
        </el-table-column>
        <el-table-column label="市场价" width="120">
          <template slot-scope="scope">
            <template>
              <span v-if="scope.row.goods_type === 'POINT'">
                {{ scope.row.exchange_money | unitPrice('￥') }} +
                {{ scope.row.exchange_point || 0 }}
                积分
              </span>
              <span v-else>{{ scope.row.mktprice | unitPrice('￥') }}</span>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="会员价" width="140" sortable="custom" prop="price">
          <template slot-scope="scope">
            <!-- {{ scope.row.price | unitPrice('￥') }} -->
            <span v-if="scope.row.goods_type === 'POINT'">
              {{ scope.row.exchange_money | unitPrice('￥') }} +
              {{ scope.row.exchange_point || 0 }}
              积分
            </span>
            <span v-else>{{ scope.row.price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column :key="Math.random()" label="成本价" width="120" v-if="canshu.self_goods=='2'">
          <template slot-scope="scope">{{ scope.row.price | unitPrice('￥') }}</template>
        </el-table-column>-->

        <el-table-column label="利润率" width="100" sortable="custom" prop="profit" v-if="is_qiye_flag">
          <template slot-scope="scope">{{ (scope.row.enterprise_profit || 0) | unitCompare('%') }}</template>
        </el-table-column>

        <el-table-column label="所属供应商" width="100" show-overflow-tooltip
          v-if="MixinIsFormEnterprise && params.self_goods === 3 && $store.getters.isSupplierName">
          <template slot-scope="scope">
            {{ scope.row.seller_name }}
          </template>
        </el-table-column>

        <el-table-column label="上架时间" width="130" prop="time" sortable="custom">
          <template slot-scope="scope">{{ scope.row.up_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>

        <el-table-column label="库存" width="100">
          <template slot-scope="scope">
            <span v-if="quantityStatus">{{ scope.row.stock_state_desc }}</span>
            <span v-else>{{ scope.row.enable_quantity > 0 ? '有货' : '无货' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="销量" width="100" sortable="custom" prop="buynum">
          <template slot-scope="scope">{{ scope.row.buy_count }}</template>
        </el-table-column>

        <el-table-column v-if="is_qiye_flag" label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="selectGoods(scope.row)">挑选</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <!--添加标签类型-->
    <el-dialog title="选择商品分类" :visible.sync="tagShow" width="600px">
      <div class="tagForm">
        <el-form label-width="180px">
          <el-select v-model="firstValue" placeholder="请选择一级分类" @change="choosefirst">
            <el-option v-for="item1 in firstData" :key="item1.cat_id" :label="item1.name"
              :value="item1.cat_id"></el-option>
          </el-select>

          <el-select v-model="secondValue" v-if="secondData.length > 0" placeholder="请选择二级分类" @change="choosesecond">
            <el-option v-for="item2 in secondData" :key="item2.cat_id" :label="item2.name"
              :value="item2.cat_id"></el-option>
          </el-select>

          <el-select v-model="thirdValue" v-if="thirdData.length > 0" placeholder="请选择三级分类" @change="choosethird">
            <el-option v-for="item3 in thirdData" :key="item3.cat_id" :label="item3.name"
              :value="item3.cat_id"></el-option>
          </el-select>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" size="small" @click="tagShow = false">取消</el-button>
        <el-button type="primary" size="small" @click="leaveIsYse">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="375px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <!-- <goodsclassify v-if="dialogVisible" :formData="goodsclassifyData"></goodsclassify> -->
      <goods-detail v-if="dialogVisible" :formData="goodsclassifyData"></goods-detail>
    </el-dialog>
    <en-gaijia-picker :showm="dialogShopShow1" @close="close" :shop_type="shopInfo.shop_type"
      @confirm1="handleShopPickerConfirm1" @res="res" :idsList="idsList" :allId="pageData.data_total"
      :is_qiye_flag="MixinIsEnterprise" :btnShow="false" />
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import { CategoryPicker } from "@/components";
import SvgIcon from "@/components/SvgIcon";
import MarketHeader from "@/views/shopGoods/market-header";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import TagZY from "@/assets/goods/tag-zy.png";
import TagJD from "@/assets/goods/tag-jd.png";
import TagJX from "@/assets/goods/tag-jx.png";
import { $goodsCategory } from "@/pages/goods/services/goods-category";
import goodsclassify from "@/components/GoodsClassify/goodsclassify";
import goodsDetail from "@/components/GoodsDetail/GoodsDetail";
export default {
  name: "goods",
  components: {
    EnTableLayout,
    MarketHeader,
    SvgIcon,
    CategoryPicker,
    goodsclassify,
    goodsDetail
  },
  data () {
    return {
      dialogVisible: false,
      goodsclassifyData: {},
      key_word: "goods_name",
      searchContent: "",
      categoryProxy: $goodsCategory.create(),
      category: "",
      market_header_search: "",
      checked: true,
      pageData: {
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      child1: [],
      child: [],
      index: 1,
      dialogShopShow1: false,
      dialogShopShow: false,
      aaaaa: "",
      idsList: [],
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      sort1: "",
      sort2: "",
      params: {
        page_no: 1,
        page_size: 20,
        // 以下不是京东馆参数
        high_price: "",
        low_price: "",
        high_mktprice: "",
        low_mktprice: "",
        profit_min: "",
        profit_max: "",
        self_goods: 0,
        // 排序
        sort: "",
        // 京东排序
        sort_type: "",
        category_path: "",
        keyword: "",
        filter_id: 1,
        // 京东馆价钱最大值和最小
        min: "",
        max: "",
        cid1: "", //一级分类
        cid2: "" //二级分类
      },
      // params: {
      //   self_goods: 0,
      //   page_no: 1,
      //   page_size: 20,
      //   // 以下不是京东馆参数
      //   high_price: '',
      //   low_price: '',
      //   high_mktprice: '',
      //   low_mktprice: '',
      //   profit_min: '',
      //   profit_max: '',
      //   // 排序
      //   sort: '',
      //   // 京东排序
      //   sort_type: '',
      //   category_path: '',
      //   keyword: '',
      //   filter_id: 1,
      //   // 京东馆价钱最大值和最小
      //   min: '',
      //   max: '',
      //   cid1: '', //一级分类
      //   cid2: '', //二级分类
      //   cat_id: '',
      //   jd_area_state:''
      // },

      /** 列表数据 */
      JDdepth: 2,
      PTdepth: 3,
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      ac: 1,
      categoryListLevel1: [],
      tempList: [],
      shop_id: "",
      pa: {
        set_under_message: ""
      },
      fdList: [],
      // 京东馆商品分类
      goods_source: 0,
      areaNum: 1,
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      areaSelectData: {
        province: "",
        city: "",
        county: "",
        town: ""
      },
      // 弹窗好像已经没地方使用了，如果你看到了，你可以把弹窗删掉
      tagShow: false,
      firstValue: "",
      secondValue: "",
      thirdValue: "",
      firstData: [],
      secondData: [],
      thirdData: [],
      category_id: 0,
      is_qiye_flag: true,
      quantityStatus: false
    };
  },
  watch: {
    "areaSelectData.province": {
      handler (newValue) {
        this.areaSelectData.city = "";
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (newValue === "") {
          this.areaNum = 1;
          this.getAreaHandle("province");
        } else {
          this.areaNum = 2;
          this.getAreaHandle("city", { id: newValue });
        }
      },
      immediate: true,
      deep: true
    },
    "areaSelectData.city": {
      handler (newValue) {
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (this.areaSelectData.province === "") return;
        if (newValue === "") this.areaNum = 2;
        else {
          this.areaNum = 3;
          this.getAreaHandle("county", { id: newValue });
        }
      },
      deep: true
    },
    "areaSelectData.county": {
      handler (newValue) {
        this.areaSelectData.town = "";
        if (this.areaSelectData.city === "") return;
        if (newValue === "") this.areaNum = 3;
        else {
          this.areaNum = 4;
          this.getAreaHandle("town", { id: newValue });
        }
      },
      deep: true
    }
  },
  filters: {

  },
  mounted () {
    this.handleGetLoginType();
  },
  methods: {
    opengoodsclassify (row) {
      this.goodsclassifyData = {
        from: 1,
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        shop_profit: row.enterprise_profit,
        islogin: true,
      }
      this.dialogVisible = true
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then(res => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then(res => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then(res => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then(res => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index]
        };
        returnData.push(data);
      });
      return returnData;
    },
    getRowTagImage (row) {
      switch (row.good_source) {
        case "自有商品":
          // case 0:
          return TagZY;
        case "京东商品":
          // case 1:
          return TagJD;
        case "普通商品":
          // case 2:
          return TagJX;
      }
    },
    leaveIsYse () {
      if (this.firstValue) {
        if (this.secondData.length > 0) {
          if (this.secondValue) {
            if (this.thirdData.length > 0) {
              if (this.thirdValue) {
                API_goods.addGoods(this.idsList, {
                  goods_ids: this.idsList,
                  goods_source: this.goods_source,
                  category_id: this.thirdValue
                }).then(res => {
                  this.tagShow = false;
                  this.GET_GoodsList();
                });
              }
            } else {
              API_goods.addGoods(this.idsList, {
                goods_ids: this.idsList,
                goods_source: this.goods_source,
                category_id: this.secondValue
              }).then(res => {
                this.tagShow = false;
                this.GET_GoodsList();
              });
            }
          }
        } else {
          API_goods.addGoods(this.idsList, {
            goods_ids: this.idsList,
            goods_source: this.goods_source,
            category_id: this.firstValue
          }).then(res => {
            this.tagShow = false;
            this.GET_GoodsList();
          });
        }
      }
    },
    choosefirst () {
      this.secondData = this.firstData.find(item => {
        return item.cat_id === this.firstValue;
      }).children;
      this.secondValue = "";
    },
    choosesecond () {
      this.thirdData = this.secondData.find(item => {
        return item.cat_id === this.secondValue;
      }).children;
      this.thirdValue = "";
    },
    choosethird () { },
    handleGetLoginType () {
      API_goods.getLoginType().then(res => {
        this.is_qiye_flag = res.parentLogin === 1;
      });
    },
    che () {
      if (this.checked) {
        this.params.filter_id = 1;
      } else {
        this.params.filter_id = 0;
      }
      this.GET_GoodsList();
    },
    close () {
      this.dialogShopShow1 = false;
    },
    addShop () {
      this.$router.push("/markets/publish");
    },
    res () {
      this.dialogShopShow = true;
      this.dialogShopShow1 = false;
    },
    handleShopPickerConfirm1 (item) {
      this.dialogShopShow1 = false;
      this.pa.shop_price = item.shop_price;
      this.pa.enterprise_price = item.enterprise_price;
      this.pa.shop_goods_status = item.shop_goods_status;
      this.pa.goods_ids = this.idsList;
      let that = this;
      API_goods.sendShop(this.pa).then(res => {
        const refreshSuccessEvent = new CustomEvent("refresh", {
          cancelable: false,
          bubbles: false,
          detail: { msg: "挑选成功" }
        });
        let refreshListener = function (e) {
          that.$message.success(e.detail.msg);
        };
        document.addEventListener("refresh", refreshListener);
        that.GET_GoodsList(refreshSuccessEvent, refreshListener);
        // API_goods.addGoods(that.idsList, {
        //   goods_ids: that.idsList,
        //   goods_source: that.goods_source
        // }).then(res1 => {

        // }).catch(err => {
        //   that.$message.error('挑选失败');
        // });
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsList();
    },

    /** 搜索事件触发 */
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      this.params.page_no = 1;
      this.params.category_path = this.category;


      this.GET_GoodsList();
    },

    selectGoods (row) {
      this.idsList = [];
      this.idsList.push(row.goods_id);

      this.$confirm("是否将所选商品放入到商品列表?", "提示", {
        type: "warning"
      }).then(() => {
        this.dialogShopShow1 = true;
      });
    },
    selectGoodsAll () {
      this.idsList = this.tempList;
      if (!this.idsList.length) {
        this.$message.error("请勾选商品");
        return;
      }
      this.$confirm("是否将所选商品放入到商品列表?", "提示", {
        type: "warning"
      }).then(() => {
        this.dialogShopShow1 = true;
      });
    },
    handleSelectionChange (list) {
      let ids = [];
      list.forEach(item => {
        ids.push(item.goods_id);
      });
      this.tempList = ids;
    },
    handleSortChange ({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.GET_GoodsList();
    },
    headerParamChange (params) {
      console.log(params.self_goods);
      console.log(params, this.params);
      this.params = {
        page_no: 1,
        page_size: 20,
        // 以下不是京东馆参数
        high_price: "",
        low_price: "",
        high_mktprice: "",
        low_mktprice: "",
        profit_min: "",
        profit_max: "",
        self_goods: 0,
        // 排序
        sort: "",
        // 京东排序
        sort_type: "",
        category_path: "",
        keyword: "",
        filter_id: 1,
        // 京东馆价钱最大值和最小
        min: "",
        max: "",
        cid1: "", //一级分类
        cid2: "", //二级分类
        ...params
      };
      this.areaSelectData = {
        province: "",
        city: "",
        county: "",
        town: "",
        jd_area_state: 0
      },
        this.key_word = "goods_name";
      this.searchContent = "";
      this.category = "";
      console.log(this.params, params);
      this.pageData.page_no = 1;
      this.GET_GoodsList();
    },
    GET_GoodsList (refreshSuccessEvent, refreshListener) {
      let params = {
        ...this.params,
        market_enable: 1,
        ...this.areaSelectData,
      };
      params[this.key_word] = this.searchContent;
      if (this.params.self_goods === 2 && this.areaSelectData.province !== "") {
        let jd_area = "" + this.areaSelectData.province + "_";
        if (this.areaSelectData.city !== "")
          jd_area += this.areaSelectData.city + "_";
        else jd_area += "0_";
        if (this.areaSelectData.county !== "")
          jd_area += this.areaSelectData.county + "_";
        else jd_area += "0_";
        if (this.areaSelectData.town !== "")
          jd_area += this.areaSelectData.town;
        else jd_area += "0";
        params.jd_area = jd_area;
        if (!params.jd_area_state) params.jd_area_state = 0;
        this.quantityStatus = true;
      } else {
        delete params.jd_area;
        delete params.jd_area_state;
        this.quantityStatus = false;
      }
      params.goods_source = this.params.self_goods.toString().includes("2")
        ? 1
        : 0;
      params.category_path = params.path ? params.path : params.category_path;
      delete params.path;
      if (params.keyword) {
        delete params.self_goods;
        delete params.goods_source;
      }
      // if (params.category_path) {
      //   params.goods_source = ''
      //   params["goods_name"] = ''
      //   params["seller_name"] = ''
      //   params["self_goods"] = ''
      // } else if (params["goods_name"] || params["seller_name"]) {
      //   if (params["goods_name"]) {
      //     params["seller_name"] = ""
      //   } else {
      //     params["goods_name"] = ""
      //   }
      //   params.category_path = ''
      //   params.goods_source = ''
      //   params["self_goods"] = ''
      // } else {
      //   params["goods_name"] = ''
      //   params["seller_name"] = ''
      //   params.category_path = ''
      // }
      if (params["goods_name"]) {
        params["seller_name"] = "";
      } else {
        params["goods_name"] = "";
      }
      if (params.goods_source === 1) {
        params.is_auth = 1;
      }
      if (params.self_goods === 0) {
        params.goods_source = "";
        params.self_goods = "";
      }
      API_goods.getGoodsListq(params).then(response => {
        this.pageData = response;
        // 重新绘制表格
        this.$refs.tableLayout.$refs.table.doLayout();
        if (refreshSuccessEvent) {
          document.dispatchEvent(refreshSuccessEvent);
          document.removeEventListener("refresh", refreshListener);
        }
      });
    }
  }
};
</script>
<style type="text/scss" lang="scss" scoped>
@import "@/components/GoodsClassify/styles";
</style>
<style lang="scss">
.goodsclassify {
  .el-dialog__body {
    padding: 0 !important;
  }
}

.el-row {
  width: 100%;
}

.address-border {
  width: 100%;
  padding: 10px;
  border: 1px solid #e9ebf0;
  border-radius: 5px;
}

.goods-page-container {}
</style>
